import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkUserIsAdmin, freeShipping, discountPerc } from './../../Utils';
import './styles.scss';



const mapState = ({ user }) => ({
  currentUser: user.currentUser
})




const AdminToolbar = props => {
  const { currentUser } = useSelector(mapState);
  

  const isAdmin = checkUserIsAdmin(currentUser);
  if (!isAdmin) return (
    <div className="adminToolbar">
      <ul>
        <li>
            {/* <p>Free shipping above £15.00. Scheduled maintenance - 24 Sept '21 04:00 AM to 09:00 AM.</p> */}
            {/* 10% off sitewide! */}
            <p>Free shipping above £{freeShipping}.00. {discountPerc>0 ? discountPerc+'% off sitewide!' : '' }</p>
            {/* <p>Free shipping above £{freeShipping}.00. {discountPerc>0 ? discountPerc+'% off sitewide for 2 days only!' : '' }</p> */}
            {/* <p>Free shipping above £{freeShipping}.00. {discountPerc>0 ? discountPerc+'% off sitewide!' : ''}. We are away on holiday, all orders will be processed from 24th December.</p> */}
        </li>
      </ul>
    </div>
  )

  return (
    <div className="adminToolbar">
      <ul>
        <li>
          <Link to="/admin">
            My admin
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default AdminToolbar;