import React from 'react';
import './styles.scss';




const banner = 'https://ik.imagekit.io/madeformefashion/HomePage/banner.jpg';
const bannermobile = 'https://ik.imagekit.io/madeformefashion/HomePage/bannermobile.jpg';


// const banner = 'https://ik.imagekit.io/madeformefashion/HomePage/bannerSale.jpg';
// const bannermobile = 'https://ik.imagekit.io/madeformefashion/HomePage/bannerMobileSale.jpg';




const HomeBanner = props => {

  return (
    <div className="directory">
      <div className="wrap">

        <img src={bannermobile} srcSet={`${bannermobile} 680w, ${banner} 980w`}  alt="Logo" 
        sizes="(max-width: 300px) 100vw, 50vw"
        aspect-ratio="1519 / 445"
        width="window.screen.width"
        height = "calc(({window.screen.width} * 0.293)"
        />

      </div>
    </div>
  );
};

export default HomeBanner;
