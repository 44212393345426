import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
// import Button from './../../forms/Button';
import './styles.scss';





const LRL1 = 'https://ik.imagekit.io/madeformefashion/HomePage/tr:w-800/LRL1.jpg';
const LRL2 = 'https://ik.imagekit.io/madeformefashion/HomePage/tr:w-800/LRL2.jpg';
const LRL3 = 'https://ik.imagekit.io/madeformefashion/HomePage/tr:w-800/LRL3.jpg';

const LRL_BG = 'https://ik.imagekit.io/madeformefashion/HomePage/LRL_Background.jpg';

const background = {
    backgroundImage: `url(${LRL_BG})`,
};

//  const configAddToCartBtn = {
//       type: 'button'
//   }


const TwoColumn = () => {
    // const history = useHistory();
    // const dispatch = useDispatch();
    // const { products } = useSelector(mapState);
    // const { data } = products;
    // let documentID = '';

    // console.log("Data", data)

    

    return(

        <div>

            <Grid container spacing={0} className="LRL LRL1" style={background} > 
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL1} alt="Logo" />
                    </div>
                </Grid>

                <Grid item xs={12} sm={5} className="TWLeft" >
                    <div className="TWdesc">
                            <h4>
                                Make any occasion extra special with our beautifully crafted dress, spendidly made with luxury fabric and exquisite workmanship.
                            </h4>

                            <Link to={`/product/SalmonPinkHi-LowDress`}>
                                <div  className="LRLbutton" >
                                    Shop Now
                                </div>
                            </Link>
                            
                    </div>
                </Grid>
                
            </Grid>



            <Grid container spacing={0} direction="row-reverse" className="LRL LRL2" style={background} >
                
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL2} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={5} className="TWLeft" >
                    <div className="TWdesc TWdescLeft">
                            <h4>
                            Made with superior quality shiny organza fabric and a full length big princess skirt with multiple layers underneath.
                                It is sure to give best look and comfort all day long.
                            </h4>

                            <Link to={`/product/DeluxePrincessBelleCostumewithAccessories`}>
                                <div className="LRLbutton" >
                                    Shop Now
                                </div>
                            </Link>

                    </div>
                </Grid>
            </Grid>


            <Grid container spacing={0} className="LRL LRL3" style={background} >
                <Grid item xs={12} sm={6} className="TWRight">
                    <div className="img">
                        <img src={LRL3} alt="Logo" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={5} className="TWLeft" >
                    <div className="TWdesc">
                            <h4>
                                Rock the streets with our trendy, practical and fun colors with your favorite character printed on them.
                            </h4>

                            <Link to={`/product/UnicornSummerDress`}>
                                <div className="LRLbutton" >
                                    Shop Now
                                </div>
                            </Link>

                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default TwoColumn;