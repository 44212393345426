import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';

// Cookies
import CookieConsent from "react-cookie-consent";

// components
import AdminToolbar from './components/AdminToolbar';
import StickyNews from './components/StickyNews';
// import ScrollToTop from './ScrollToTop.js';kw
import ScrollToTop from 'react-router-scroll-top';

// hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';

// layouts
import MainLayout from './layouts/MainLayout';
import HomepageLayout from './layouts/HomepageLayout';
import AdminLayout from './layouts/AdminLayout';
import DashboardLayout from './layouts/DashboardLayout';

// pages
import Homepage from './pages/Homepage';
import Search from './pages/Search';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Order from './pages/Order';
import SizeChart from './pages/SizeChart';
import ShippingPolicy from './pages/ShippingPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import AboutUs from './pages/AboutUs';
import PaymentTrue from './pages/PaymentTrue';

// Utils
import { holiday } from './Utils'

import './default.scss';

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.localStorage.clear();
    
    dispatch(checkUserSession());

  }, [dispatch]);

  return (
    <div className="App">
      <AdminToolbar />
        {
          (holiday) &&
            <StickyNews />
        }
      <ScrollToTop>
      <Switch>
        <Route exact path="/" render={() => (
          <HomepageLayout>
            <Homepage />
          </HomepageLayout>
        )}
        />
        <Route exact path="/search" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/search/:filterType" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/product/:productID" render={() => (
          <MainLayout>
            <ProductDetails />
          </MainLayout>
        )} />
        <Route path="/cart" render={() => (
          <MainLayout>
            <Cart />
          </MainLayout>
        )} />
        <Route path="/true" render={() => (
            <MainLayout>
              <PaymentTrue />
            </MainLayout>
        )} />
        <Route path="/shipping-policy" render={() => (
          <MainLayout>
            <ShippingPolicy />
          </MainLayout>
        )} />
        <Route path="/return-policy" render={() => (
          <MainLayout>
            <ReturnPolicy />
          </MainLayout>
        )} />
        <Route path="/privacy-policy" render={() => (
          <MainLayout>
            <PrivacyPolicy />
          </MainLayout>
        )} />
        <Route path="/terms-of-service" render={() => (
          <MainLayout>
            <TermsOfService />
          </MainLayout>
        )} />
        <Route path="/about-us" render={() => (
          <MainLayout>
            <AboutUs />
          </MainLayout>
        )} />
        <Route path="/registration" render={() => (
          <MainLayout>
            <Registration />
          </MainLayout>
        )} />
        <Route path="/login"
          render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )} />
        <Route path="/recovery" render={() => (
          <MainLayout>
            <Recovery />
          </MainLayout>
        )} />
        <Route path="/dashboard" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Order />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/admin" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <Admin />
            </AdminLayout>
          </WithAdminAuth>
        )} />
        <Route path="/size-guide" render={() => (
          <MainLayout>
            <SizeChart />
          </MainLayout>
        )} />
      </Switch>
      </ScrollToTop>
      <CookieConsent 
        className="cookies"
        style={{ background: "#e9e7f9" }}
        buttonStyle={{ background: "#b39adc" }}
        contentStyle={{ color: "#4533cc" }}
        >
        We use cookies to offer you the best experience possible when shopping with us. 
        You can find out more about which cookies we use here <a href="/privacy-policy">Privacy Policy</a>.
      </CookieConsent>

      

    </div>
  );
}

export default App;
