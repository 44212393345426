import React from 'react';
import './../../components/Information/styles.scss'


const PrivacyPolicy = () => {
    window.scrollTo(0, 0);
    return(
        <div className="infoContainer">
            <div className="Title">
                <h2>
                    Privacy Policy
                </h2>
            </div>

            <div className="infoDesc">
                <p>
                    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.madeformefashion.com (the “Site”).
                </p>
                <h5>
                    PERSONAL INFORMATION WE COLLECT
                </h5>
                <p>
                    When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically collected information as “Device Information”.
                </p>
                <p>
                    We collect Device Information using the following technologies: 
                </p>
                    <ul>
                        <li>
                            “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                        </li>
                        <li>
                            “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                        </li>
                        <li>
                            “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.
                        </li>
                    </ul>
                <p>
                    Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We refer to this information as “Order Information”.
                </p>
                <p>
                    When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                </p>
                



                <h5>
                    KEEPING YOU SECURE
                </h5>
                <p>
                    When you shop with us we’ll ask you for certain information (such as your email address, name, address, and where you purchase a product, your payment details) to make sure that your order and delivery go smoothly. Any information you hand over reaches us fully encrypted through an SSL – a special security layer added to your account and checkout pages. You can see when you’re on a secure page, because the address in your browser will begin with https instead of http. And you can sleep easy, because your credit card details are processed in the UK.
                </p>




                <h5>
                    KEEPING YOUR PASSWORD SAFE
                </h5>
                <p>
                    Remember to keep your account password safe! We can help you to reset it now and then, but you’ll need to make sure that it stays with you and only you. Giving it out can represent a security risk and Made for me fashion will not be liable for any loss or damage that might arise from this.
                </p>




                <h5>
                    HOW DO WE USE YOUR PERSONAL INFORMATION? 
                </h5>
                <p>
                    We use the Order Information that we collect generally to fulfil any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
                </p>
                    <ul>
                        <li>
                            Helping to process your order
                        </li>
                        <li>
                            Communicate with you
                        </li>
                        <li>
                            Providing admin support for your account
                        </li>
                        <li>
                            Screen our orders for potential risk or fraud(when we might also share it with the police or other authorities if required by law)
                        </li>
                        <li>
                            When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services
                        </li>
                    </ul>
                <p>
                    We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
                </p>
                



                <h5>
                    COOKIES
                </h5>
                <p>
                    Most websites use cookies, and we do too, to keep offering you the best service. A cookie is way of transferring little bits of information from our website back to us, which means, for example, that we can see which pages or products are our most popular and then create a better experience for you in future.
                </p>
                <p>
                    There are a few different kinds of cookies and they each do slightly different things.
                </p>
                <p className="subHeading">
                    <b>Website functionality cookies</b>
                </p>
                <p>
                    These simply enable you to browse our website and use all our functions, like your Shopping Cart or Account pages.
                </p>
                <p className="subHeading">
                    <b>Website analysis cookies</b>
                </p>
                <p>
                    These help us to understand how our customers are using our website, and we use this information to constantly improve the way we do things.
                </p>
                <p className="subHeading">
                    <b>Customer preference cookies</b>
                </p>
                <p>
                    These help us to make sure your online experience is simple, easy and relevant to you.
                </p>
                <p className="subHeading">
                    <b>Targeting cookies</b>
                </p>
                <p>
                    We sometimes run online advertising campaigns, and, if we think you’ll be interested in what we have to say, targeting cookies will help us to deliver that message to you. More importantly, they limit the amount of times you see any of our ads.
                </p>
                <p>
                    To find out more about cookies and how you can manage your cookie preferences please visit https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options
                </p>
                



                <h5>
                    SHARING YOUR PERSONAL INFORMATION
                </h5>
                <p>
                    We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Firebase to power our online store--you can read more about how Firebase uses your Personal Information here: https://policies.google.com/privacy. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://policies.google.com/privacy. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                </p>
                <p>
                    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                </p>
                



                <h5>
                    BEHAVIOURAL ADVERTISING
                </h5>
                <p>
                    As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about targeted advertising visit here https://www.gov.uk/government/publications/cdei-review-of-online-targeting/online-targeting-final-report-and-recommendations.
                </p>
                <p>
                    You can opt out of targeted advertising by using the links below:
                </p>
                    <ul>
                        <li>
                            Facebook: https://www.facebook.com/adpreferences/advertisers
                        </li>
                        <li>
                            Google: https://www.google.com/settings/ads/anonymous
                        </li>
                        <li>
                            Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                        </li>
                    </ul>
                <p>
                    Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: https://optout.aboutads.info.
                </p>
                



                <h5>
                    YOUR RIGHTS
                </h5>
                <p>
                    You have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                </p>
                <p>
                    Additionally, we note that we are processing your information to fulfil contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information may be transferred outside of the UK.
                </p>




                <h5>
                    DATA RETENTION
                </h5>
                <p>
                    When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                </p>
                


                <h5>
                    CHANGES
                </h5>
                <p>
                    We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                </p>
                


                <h5>
                    CONTACT US
                </h5>
                <p>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e mail at info@madeformefashion.com.
                </p>
                


               
                

            </div>
        </div>
    )
}

export default PrivacyPolicy;