import React from 'react';
import './styles.scss';
import './../../components/Information/styles.scss';
import Grid from '@material-ui/core/Grid';
import Reviews from '../../components/Reviews';

const PromiseImg = "https://ik.imagekit.io/madeformefashion/AboutUs/Promise.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1649318515454";
const NameStory = "https://ik.imagekit.io/madeformefashion/AboutUs/NameStory.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1649318514775";


const AboutUs = () => {
    window.scrollTo(0, 0);

    return(
        <div className="infoContainer">
            <div className="Title">
                <h2>
                    About US
                </h2>
            </div>

                <div className="whovr">
                    <h5>
                        Who we are
                    </h5>
                    <h5>
                        <i>Made for me fashion was founded in 2021 with the aim to provide comfortable and breathtakingly beautiful princess dresses and accessories for little girls.</i>
                    </h5>
                    <p>
                        We are dedicated to delivering high-quality products and excellent customer service. We believe a girl has the right to dress well, and parents don’t have to spend a lot of money to own what she loves. We have gone to great lengths to build a renowned collection of lush dresses for her. Our exclusive Boutique is equipped to impress!
                    </p>
                </div>


            <Grid container spacing={0} className="withImg"> 
                <Grid item xs={12} sm={3} className="promiseImg" >
                        <img src={PromiseImg} alt={"Founder"} verticalalign={"center"}/>
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={12} sm={8} className="promiseDescGrid" >
                    <div className="promiseDesc" >
                        <h5>
                            Our Promise
                        </h5>        
                        <p>
                        The brand Made for me fashion has been inspired by my two little girls. After learning so much about their tastes, I have realised, comfort and style go hand to hand. Hence we only bring the selective fashion that is jaw-droppingly gorgeous and fully cotton lined inside for extra comfort.
                        </p>
                        <p>
                        The joy of caring for and dressing your child is simply a pleasure that every mother deserves to experience. So from our home to yours, we hope you enjoy your shopping experience at Made for me fashion.
                        </p>
                        <p style={{margin:0}}>
                            - Kashish Wadhwani
                        </p>
                        <p>
                            <span>&nbsp;&nbsp;&nbsp;</span>(Founder)
                        </p>
                    </div>    
                </Grid>
            </Grid>

            <Grid container spacing={0} direction="row-reverse" className="storyDescContainer" >
                <Grid item xs={12} sm={3} className="storyImg"  >
                        <img src={NameStory} alt={"child"} verticalalign={"center"}/>
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={12} sm={8} className="storyDescGrid" >
                    <div className="storyDesc">
                        <h5>
                            Story behind the name
                        </h5>        
                        <p>
                            <i>“look mama, this dress looks so good on me, as if it was made just for me”</i>
                        </p>
                        <p>
                            Naming a brand is one of the vital and difficult decision to make. I wanted to give my brand a name that the person who owns it could relate to it personally. Then one day my daughter, gazing herself in the mirror and admiring her favourite unicorn costume suddenly said, ‘look mama, this dress looks so good on me, as if it was made just for me’. I loved her explaination and that’s how ‘made for me fashion’ was born! I want every girl who wears my creation, to feel exactly the same, as if it was made just for her.
                        </p>
                    </div>    
                    </Grid>
            </Grid>

            <Reviews/>

        </div>
    )
}

export default AboutUs;